import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

function BlogPostRedirect() {
  const { short } = useParams(); // Get the "short" parameter from the URL
  const navigate = useNavigate(); // For navigation
  const timestamp = new Date().getTime(); // Add timestamp to bypass cache

  useEffect(() => {
    // Fetch post data from the API
    fetch(`https://www.pixelliongroup.com/seotic/getBlogs.php?short=${short}&_=${timestamp}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch post data");
        }
        return response.json();
      })
      .then((data) => {
        const matchedPost = data.find((p) => p.short === short); // Match the post by the "short" parameter
        if (matchedPost) {
          // Redirect to the new route with the correct parameter
          navigate(`/blog/${matchedPost.parama}`, { replace: true });
        } else {
          // Redirect to the home page if not found
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        console.error("Error fetching post:", err);
        // Redirect to the home page on error
        navigate("/", { replace: true });
      });
  }, [short, navigate]);

  return (
    <div className="text-center py-10">
      <i className="bx bx-circle bx-burst bx-rotate-270 bx-fw"></i> Redirecting...
    </div>
  );
}

export default BlogPostRedirect;
