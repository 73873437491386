import React, { useState } from "react";
import { lazy } from 'react';
function Features() {
  const [activeTab, setActiveTab] = useState("content1");
 const [loading, setLoading] = useState(false);
 const [imageLoading, setImageLoading] = useState(true); // State for image loading
 
  const showTab = (tabId) => {
    setActiveTab(tabId);
  };
  

  if (loading) {
    return (
      <>
        <div className="bg-slate-100 h-full">
          <div className="container mx-auto py-12">
            <div className="text-center"><i className='bx bx-circle bx-burst bx-rotate-270 bx-fw'></i>Loading...</div>
          </div>
        </div>
      </>
    );
  }


  return (
    <section id="features" className="pb-32  bg-gradient-to-t from-amber-50  to-white ">
      <div className="container mx-auto">
        
        <div className="flex justify-center space-x-8 mb-8 hidden md:flex">
          <button onClick={() => showTab("content1")} className={`flex items-center px-5 py-2 rounded-lg ${activeTab === "content1" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M2.5 8.18677C2.60406 6.08705 2.91537 4.77792 3.84664 3.84664C4.77792 2.91537 6.08705 2.60406 8.18677 2.5M21.5 8.18677C21.3959 6.08705 21.0846 4.77792 20.1534 3.84664C19.2221 2.91537 17.9129 2.60406 15.8132 2.5M15.8132 21.5C17.9129 21.3959 19.2221 21.0846 20.1534 20.1534C21.0846 19.2221 21.3959 17.9129 21.5 15.8132M8.18676 21.5C6.08705 21.3959 4.77792 21.0846 3.84664 20.1534C2.91537 19.2221 2.60406 17.9129 2.5 15.8132" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.49986 12H21.4999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M6 12C6 8.68629 8.68629 6 12 6C12 7.65685 13.3431 9 15 9C15.6755 9 16.2989 8.77672 16.8004 8.39993C17.5536 9.40273 18 10.6492 18 12M17.1973 15C16.1599 16.7934 14.2208 18 12 18C9.77915 18 7.84012 16.7934 6.80269 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg> SEO Audits
          </button>
          <button onClick={() => showTab("content2")} className={` flex items-center px-5 py-2 rounded-lg ${activeTab === "content2" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="mr-2" fill="none">
    <path d="M9.14339 10.691L9.35031 10.4841C11.329 8.50532 14.5372 8.50532 16.5159 10.4841C18.4947 12.4628 18.4947 15.671 16.5159 17.6497L13.6497 20.5159C11.671 22.4947 8.46279 22.4947 6.48405 20.5159C4.50532 18.5372 4.50532 15.329 6.48405 13.3503L6.9484 12.886" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M17.0516 11.114L17.5159 10.6497C19.4947 8.67095 19.4947 5.46279 17.5159 3.48405C15.5372 1.50532 12.329 1.50532 10.3503 3.48405L7.48405 6.35031C5.50532 8.32904 5.50532 11.5372 7.48405 13.5159C9.46279 15.4947 12.671 15.4947 14.6497 13.5159L14.8566 13.309" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>Backlinks
          </button>
          <button onClick={() => showTab("content3")} className={`flex items-center px-5 py-2 rounded-lg ${activeTab === "content3" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="mr-2" fill="none">
    <path d="M4 10C3.51076 9.96288 3.16925 9.87932 2.88886 9.69117C2.67048 9.54463 2.48298 9.35634 2.33706 9.13703C2 8.63045 2 7.92524 2 6.51483C2 5.10442 2 4.39921 2.33706 3.89263C2.48298 3.67332 2.67048 3.48502 2.88886 3.33849C3.39331 3 4.09554 3 5.5 3H18.5C19.9045 3 20.6067 3 21.1111 3.33849C21.3295 3.48502 21.517 3.67332 21.6629 3.89263C22 4.39921 22 5.10442 22 6.51483C22 7.80256 22 8.50243 21.7435 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M18.2 18.2L21 21M19.6 13.3C19.6 9.82057 16.7794 7 13.3 7C9.82057 7 7 9.82057 7 13.3C7 16.7794 9.82057 19.6 13.3 19.6C16.7794 19.6 19.6 16.7794 19.6 13.3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>Keyword Research
          </button>
          <button onClick={() => showTab("content4")} className={`flex items-center px-5 py-2 rounded-lg ${activeTab === "content4" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="mr-2" fill="none">
    <path d="M11.1075 5.57624C11.3692 6.02707 11.5 6.25248 11.5 6.5C11.5 6.74752 11.3692 6.97293 11.1075 7.42376L9.85804 9.57624C9.59636 10.0271 9.46551 10.2525 9.25 10.3762C9.03449 10.5 8.7728 10.5 8.24943 10.5H5.75057C5.2272 10.5 4.96551 10.5 4.75 10.3762C4.53449 10.2525 4.40364 10.0271 4.14196 9.57624L2.89253 7.42376C2.63084 6.97293 2.5 6.74752 2.5 6.5C2.5 6.25248 2.63084 6.02707 2.89253 5.57624L4.14196 3.42376C4.40364 2.97293 4.53449 2.74752 4.75 2.62376C4.96551 2.5 5.2272 2.5 5.75057 2.5L8.24943 2.5C8.7728 2.5 9.03449 2.5 9.25 2.62376C9.46551 2.74752 9.59636 2.97293 9.85804 3.42376L11.1075 5.57624Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.1075 11.5762C21.3692 12.0271 21.5 12.2525 21.5 12.5C21.5 12.7475 21.3692 12.9729 21.1075 13.4238L19.858 15.5762C19.5964 16.0271 19.4655 16.2525 19.25 16.3762C19.0345 16.5 18.7728 16.5 18.2494 16.5H15.7506C15.2272 16.5 14.9655 16.5 14.75 16.3762C14.5345 16.2525 14.4036 16.0271 14.142 15.5762L12.8925 13.4238C12.6308 12.9729 12.5 12.7475 12.5 12.5C12.5 12.2525 12.6308 12.0271 12.8925 11.5762L14.142 9.42376C14.4036 8.97293 14.5345 8.74752 14.75 8.62376C14.9655 8.5 15.2272 8.5 15.7506 8.5L18.2494 8.5C18.7728 8.5 19.0345 8.5 19.25 8.62376C19.4655 8.74752 19.5964 8.97293 19.858 9.42376L21.1075 11.5762Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.1075 16.5762C11.3692 17.0271 11.5 17.2525 11.5 17.5C11.5 17.7475 11.3692 17.9729 11.1075 18.4238L9.85804 20.5762C9.59636 21.0271 9.46551 21.2525 9.25 21.3762C9.03449 21.5 8.7728 21.5 8.24943 21.5H5.75057C5.2272 21.5 4.96551 21.5 4.75 21.3762C4.53449 21.2525 4.40364 21.0271 4.14196 20.5762L2.89253 18.4238C2.63084 17.9729 2.5 17.7475 2.5 17.5C2.5 17.2525 2.63084 17.0271 2.89253 16.5762L4.14196 14.4238C4.40364 13.9729 4.53449 13.7475 4.75 13.6238C4.96551 13.5 5.2272 13.5 5.75057 13.5L8.24943 13.5C8.7728 13.5 9.03449 13.5 9.25 13.6238C9.46551 13.7475 9.59636 13.9729 9.85804 14.4238L11.1075 16.5762Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>Competitor Analysis
          </button>
          <button onClick={() => showTab("content5")} className={`flex items-center px-5 py-2 rounded-lg ${activeTab === "content5" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="mr-2" fill="none">
    <path d="M8.9835 1.99998C6.17689 2.06393 4.53758 2.33109 3.41752 3.44727C2.43723 4.42416 2.10954 5.79742 2 7.99998M15.0165 1.99998C17.8231 2.06393 19.4624 2.33109 20.5825 3.44727C21.5628 4.42416 21.8905 5.79742 22 7.99998M15.0165 22C17.8231 21.9361 19.4624 21.6689 20.5825 20.5527C21.5628 19.5758 21.8905 18.2026 22 16M8.9835 22C6.17689 21.9361 4.53758 21.6689 3.41752 20.5527C2.43723 19.5758 2.10954 18.2026 2 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 15L17 17M16 11.5C16 9.01468 13.9853 6.99998 11.5 6.99998C9.01469 6.99998 7 9.01468 7 11.5C7 13.9853 9.01469 16 11.5 16C13.9853 16 16 13.9853 16 11.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>Rank Monitor
          </button>
          
          <button onClick={() => showTab("content6")} className={`flex items-center px-5 py-2 rounded-lg ${activeTab === "content6" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="mr-2" fill="none">
    <path d="M10 7L9.48415 8.39405C8.80774 10.222 8.46953 11.136 7.80278 11.8028C7.13603 12.4695 6.22204 12.8077 4.39405 13.4842L3 14L4.39405 14.5158C6.22204 15.1923 7.13603 15.5305 7.80278 16.1972C8.46953 16.864 8.80774 17.778 9.48415 19.6059L10 21L10.5158 19.6059C11.1923 17.778 11.5305 16.864 12.1972 16.1972C12.864 15.5305 13.778 15.1923 15.6059 14.5158L17 14L15.6059 13.4842C13.778 12.8077 12.864 12.4695 12.1972 11.8028C11.5305 11.136 11.1923 10.222 10.5158 8.39405L10 7Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M18 3L17.7789 3.59745C17.489 4.38087 17.3441 4.77259 17.0583 5.05833C16.7726 5.34408 16.3809 5.48903 15.5975 5.77892L15 6L15.5975 6.22108C16.3809 6.51097 16.7726 6.65592 17.0583 6.94167C17.3441 7.22741 17.489 7.61913 17.7789 8.40255L18 9L18.2211 8.40255C18.511 7.61913 18.6559 7.22741 18.9417 6.94166C19.2274 6.65592 19.6191 6.51097 20.4025 6.22108L21 6L20.4025 5.77892C19.6191 5.48903 19.2274 5.34408 18.9417 5.05833C18.6559 4.77259 18.511 4.38087 18.2211 3.59745L18 3Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
</svg>AI Tools 
          </button>

          
        </div>
        
        {/* Mobile */}
        <div className="flex justify-center  mb-8 md:hidden">
          <button onClick={() => showTab("content1")} className={`flex items-center p-5 rounded-lg ${activeTab === "content1" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M2.5 8.18677C2.60406 6.08705 2.91537 4.77792 3.84664 3.84664C4.77792 2.91537 6.08705 2.60406 8.18677 2.5M21.5 8.18677C21.3959 6.08705 21.0846 4.77792 20.1534 3.84664C19.2221 2.91537 17.9129 2.60406 15.8132 2.5M15.8132 21.5C17.9129 21.3959 19.2221 21.0846 20.1534 20.1534C21.0846 19.2221 21.3959 17.9129 21.5 15.8132M8.18676 21.5C6.08705 21.3959 4.77792 21.0846 3.84664 20.1534C2.91537 19.2221 2.60406 17.9129 2.5 15.8132" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.49986 12H21.4999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M6 12C6 8.68629 8.68629 6 12 6C12 7.65685 13.3431 9 15 9C15.6755 9 16.2989 8.77672 16.8004 8.39993C17.5536 9.40273 18 10.6492 18 12M17.1973 15C16.1599 16.7934 14.2208 18 12 18C9.77915 18 7.84012 16.7934 6.80269 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
          </button>
          <button onClick={() => showTab("content2")} className={` flex items-center p-5 rounded-lg ${activeTab === "content2" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"  fill="none">
    <path d="M9.14339 10.691L9.35031 10.4841C11.329 8.50532 14.5372 8.50532 16.5159 10.4841C18.4947 12.4628 18.4947 15.671 16.5159 17.6497L13.6497 20.5159C11.671 22.4947 8.46279 22.4947 6.48405 20.5159C4.50532 18.5372 4.50532 15.329 6.48405 13.3503L6.9484 12.886" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M17.0516 11.114L17.5159 10.6497C19.4947 8.67095 19.4947 5.46279 17.5159 3.48405C15.5372 1.50532 12.329 1.50532 10.3503 3.48405L7.48405 6.35031C5.50532 8.32904 5.50532 11.5372 7.48405 13.5159C9.46279 15.4947 12.671 15.4947 14.6497 13.5159L14.8566 13.309" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>
          </button>
          <button onClick={() => showTab("content3")} className={`flex items-center p-5 rounded-lg ${activeTab === "content3" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M4 10C3.51076 9.96288 3.16925 9.87932 2.88886 9.69117C2.67048 9.54463 2.48298 9.35634 2.33706 9.13703C2 8.63045 2 7.92524 2 6.51483C2 5.10442 2 4.39921 2.33706 3.89263C2.48298 3.67332 2.67048 3.48502 2.88886 3.33849C3.39331 3 4.09554 3 5.5 3H18.5C19.9045 3 20.6067 3 21.1111 3.33849C21.3295 3.48502 21.517 3.67332 21.6629 3.89263C22 4.39921 22 5.10442 22 6.51483C22 7.80256 22 8.50243 21.7435 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M18.2 18.2L21 21M19.6 13.3C19.6 9.82057 16.7794 7 13.3 7C9.82057 7 7 9.82057 7 13.3C7 16.7794 9.82057 19.6 13.3 19.6C16.7794 19.6 19.6 16.7794 19.6 13.3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
          </button>
          <button onClick={() => showTab("content4")} className={`flex items-center p-5 rounded-lg ${activeTab === "content4" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M11.1075 5.57624C11.3692 6.02707 11.5 6.25248 11.5 6.5C11.5 6.74752 11.3692 6.97293 11.1075 7.42376L9.85804 9.57624C9.59636 10.0271 9.46551 10.2525 9.25 10.3762C9.03449 10.5 8.7728 10.5 8.24943 10.5H5.75057C5.2272 10.5 4.96551 10.5 4.75 10.3762C4.53449 10.2525 4.40364 10.0271 4.14196 9.57624L2.89253 7.42376C2.63084 6.97293 2.5 6.74752 2.5 6.5C2.5 6.25248 2.63084 6.02707 2.89253 5.57624L4.14196 3.42376C4.40364 2.97293 4.53449 2.74752 4.75 2.62376C4.96551 2.5 5.2272 2.5 5.75057 2.5L8.24943 2.5C8.7728 2.5 9.03449 2.5 9.25 2.62376C9.46551 2.74752 9.59636 2.97293 9.85804 3.42376L11.1075 5.57624Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.1075 11.5762C21.3692 12.0271 21.5 12.2525 21.5 12.5C21.5 12.7475 21.3692 12.9729 21.1075 13.4238L19.858 15.5762C19.5964 16.0271 19.4655 16.2525 19.25 16.3762C19.0345 16.5 18.7728 16.5 18.2494 16.5H15.7506C15.2272 16.5 14.9655 16.5 14.75 16.3762C14.5345 16.2525 14.4036 16.0271 14.142 15.5762L12.8925 13.4238C12.6308 12.9729 12.5 12.7475 12.5 12.5C12.5 12.2525 12.6308 12.0271 12.8925 11.5762L14.142 9.42376C14.4036 8.97293 14.5345 8.74752 14.75 8.62376C14.9655 8.5 15.2272 8.5 15.7506 8.5L18.2494 8.5C18.7728 8.5 19.0345 8.5 19.25 8.62376C19.4655 8.74752 19.5964 8.97293 19.858 9.42376L21.1075 11.5762Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.1075 16.5762C11.3692 17.0271 11.5 17.2525 11.5 17.5C11.5 17.7475 11.3692 17.9729 11.1075 18.4238L9.85804 20.5762C9.59636 21.0271 9.46551 21.2525 9.25 21.3762C9.03449 21.5 8.7728 21.5 8.24943 21.5H5.75057C5.2272 21.5 4.96551 21.5 4.75 21.3762C4.53449 21.2525 4.40364 21.0271 4.14196 20.5762L2.89253 18.4238C2.63084 17.9729 2.5 17.7475 2.5 17.5C2.5 17.2525 2.63084 17.0271 2.89253 16.5762L4.14196 14.4238C4.40364 13.9729 4.53449 13.7475 4.75 13.6238C4.96551 13.5 5.2272 13.5 5.75057 13.5L8.24943 13.5C8.7728 13.5 9.03449 13.5 9.25 13.6238C9.46551 13.7475 9.59636 13.9729 9.85804 14.4238L11.1075 16.5762Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
          </button>
          <button onClick={() => showTab("content5")} className={`flex items-center p-5 rounded-lg ${activeTab === "content5" ? "bg-yellow-300 text-yellow-900" : "text-gray-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M8.9835 1.99998C6.17689 2.06393 4.53758 2.33109 3.41752 3.44727C2.43723 4.42416 2.10954 5.79742 2 7.99998M15.0165 1.99998C17.8231 2.06393 19.4624 2.33109 20.5825 3.44727C21.5628 4.42416 21.8905 5.79742 22 7.99998M15.0165 22C17.8231 21.9361 19.4624 21.6689 20.5825 20.5527C21.5628 19.5758 21.8905 18.2026 22 16M8.9835 22C6.17689 21.9361 4.53758 21.6689 3.41752 20.5527C2.43723 19.5758 2.10954 18.2026 2 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 15L17 17M16 11.5C16 9.01468 13.9853 6.99998 11.5 6.99998C9.01469 6.99998 7 9.01468 7 11.5C7 13.9853 9.01469 16 11.5 16C13.9853 16 16 13.9853 16 11.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
          </button>
          
          <button onClick={() => showTab("content6")} className={`flex items-center p-5 rounded-lg ${activeTab === "content6" ? "bg-yellow-300 text-yellow-900" : "text-slate-600"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M10 7L9.48415 8.39405C8.80774 10.222 8.46953 11.136 7.80278 11.8028C7.13603 12.4695 6.22204 12.8077 4.39405 13.4842L3 14L4.39405 14.5158C6.22204 15.1923 7.13603 15.5305 7.80278 16.1972C8.46953 16.864 8.80774 17.778 9.48415 19.6059L10 21L10.5158 19.6059C11.1923 17.778 11.5305 16.864 12.1972 16.1972C12.864 15.5305 13.778 15.1923 15.6059 14.5158L17 14L15.6059 13.4842C13.778 12.8077 12.864 12.4695 12.1972 11.8028C11.5305 11.136 11.1923 10.222 10.5158 8.39405L10 7Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M18 3L17.7789 3.59745C17.489 4.38087 17.3441 4.77259 17.0583 5.05833C16.7726 5.34408 16.3809 5.48903 15.5975 5.77892L15 6L15.5975 6.22108C16.3809 6.51097 16.7726 6.65592 17.0583 6.94167C17.3441 7.22741 17.489 7.61913 17.7789 8.40255L18 9L18.2211 8.40255C18.511 7.61913 18.6559 7.22741 18.9417 6.94166C19.2274 6.65592 19.6191 6.51097 20.4025 6.22108L21 6L20.4025 5.77892C19.6191 5.48903 19.2274 5.34408 18.9417 5.05833C18.6559 4.77259 18.511 4.38087 18.2211 3.59745L18 3Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
</svg>
          </button>

          
        </div>

        

        {/* Content Sections */}
        {activeTab === "content1" && (
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="text-left flex items-center  px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Website Audits</h3>
                <p className="text-sm">
                  Our Website Audit tool offers an in-depth look into your site’s SEO health, page quality, and performance.
                  Designed for quick, actionable insights, it’s the essential tool to help you drive more traffic and engagement.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">SEO Audit</h2>
                    <p className="text-sm">Improve keywords, meta tags, and technical SEO for better visibility.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Page Quality Check</h2>
                    <p className="text-sm">Ensure smooth navigation, mobile-friendliness, and clear content on every page.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Quick Improvements</h2>
                    <p className="text-sm">Get prioritized recommendations to boost SEO, content, and speed.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Core Web Vitals</h2>
                    <p className="text-sm">Check speed, accessibility, and user experience based on Google’s standards.</p>
                  </div>
                </div>
              </div>
            </div>
           <div className="pl-10 pt-10 hidden md:block">
              <img loading="lazy" className=" rounded-tl-xl rounded-br-lg" src="web-audit.png" alt="Keyword Research" />
            </div>
          </div>
        )}

        {activeTab === "content2" && (
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="pr-10 pt-10 hidden md:block">
              <img loading="lazy" className="rounded-tr-xl rounded-bl-lg animate-fade animate-once" src="backlinks.png" alt="Backlink Analysis" />
            </div>
            <div className="text-left flex items-center px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Backlinks Analysis</h3>
                <p className="text-sm">
                  Our Backlinks feature provides essential insights to help you manage and strengthen your backlink strategy.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">By Month</h2>
                    <p className="text-sm">Monitor backlink growth month-by-month to understand trends and impact.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Top Referring Domains</h2>
                    <p className="text-sm">See which domains drive the most value and authority to your site.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Detail List</h2>
                    <p className="text-sm">Access a full list of backlinks with domain details, URLs, and DA.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Broken Backlinks</h2>
                    <p className="text-sm">Easily identify and repair broken backlinks to retain link value.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "content3" && (
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="text-left flex items-center px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Keywords Research</h3>
                <p className="text-sm">
                  Our Keyword Research tool provides the insights you need to target the right keywords and drive more traffic.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">Keyword Suggestions</h2>
                    <p className="text-sm">Get a list of keyword ideas tailored to your niche and audience.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Monthly Volume</h2>
                    <p className="text-sm">See the monthly search volume to prioritize high-interest keywords.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Competitions</h2>
                    <p className="text-sm">Understand how competitive each keyword is to improve targeting.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Trend Rate</h2>
                    <p className="text-sm">Monitor changes in search popularity to stay ahead of market shifts.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-10 pt-10 hidden md:block">
              <img loading="lazy" className=" rounded-tl-xl rounded-br-lg animate-fade animate-once" src="keywords-research.png" alt="Keyword Research" />
            </div>
          </div>
        )}

        {activeTab === "content4" && (
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="pr-10 pt-10 hidden md:block">
              <img loading="lazy" className=" rounded-tr-xl rounded-bl-lg  animate-fade animate-once" src="competitors-analysis.png" alt="Competitors Analysis" />
            </div>
            <div className="text-left flex items-center px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Competitor Analysis</h3>
                <p className="text-sm">
                  Our Competitor Analysis tool helps you understand and outpace your competition.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">Common Keywords</h2>
                    <p className="text-sm">Discover the keywords you and your competitors both target.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Gap Keywords</h2>
                    <p className="text-sm">Find keywords your competitors rank for that you’re missing.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Estimate Traffic</h2>
                    <p className="text-sm">View estimated traffic to understand your competitors' online visibility.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Ranked List</h2>
                    <p className="text-sm">Get a ranked list of competitors based on traffic and keyword performance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "content5" && (
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="text-left flex items-center px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Rank Monitor</h3>
                <p className="text-sm">
                  Our Rank Monitor tool helps you stay updated on keyword rankings and make data-driven improvements.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">Rank History</h2>
                    <p className="text-sm">View historical ranking data to measure growth and performance trends.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">SERP by Keyword</h2>
                    <p className="text-sm">Check where your keywords stand in search engine results pages (SERPs).</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Ranked URL</h2>
                    <p className="text-sm">Discover which URLs are ranking for each keyword to optimize and refine content.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-10 pt-10 hidden md:block">
              <img loading="lazy" className="rounded-tl-xl rounded-br-lg animate-fade animate-once" src="/rank-monitor.png" alt="Rank Monitor" />
            </div>
          </div>
        )}
        
        {activeTab === "content6" && (
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
          <div className="pr-10 pt-10 hidden md:block">
              <img loading="lazy" className=" rounded-tr-xl rounded-bl-lg  animate-fade animate-once" src="ai-tools.png" alt="Ai Tools" />
            </div>

            <div className="text-left flex items-center px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">AI Tools</h3>
                <p className="text-sm">
                 Our AI tools can help you improve rankings, optimize on page content, and create SEO ready blog posts with ease.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                 
                 <div>
                    <h2 className="text-xl mb-2">AI Summarize</h2>
                    <p className="text-sm">Receive actionable improvement suggestions and next step guidance based on your website data.</p>
                  </div>
                 
                  <div>
                    <h2 className="text-xl mb-2">AI Blog Writer</h2>
                    <p className="text-sm">Effortlessly create engaging, SEO friendly blog content tailored to your audience.</p>
                  </div>
                  
                   
                  
                   <div>
                    <h2 className="text-xl mb-2">AI Code Suggestion</h2>
                    <p className="text-sm">Get smarter, faster coding with AI powered suggestions.</p>
                  </div>
                  
                </div>
              </div>
            </div>
                   </div>
        )}

      </div>
      
    </section>
  );
}

export default Features;
